/* .EventsGroupsNav {
    margin-left: 0;
    padding-left: 0;
    display: flex;
    flex-direction: row;
    flex: auto;
    justify-content: flex-start;
    justify-items: flex-start;
    align-items: flex-start;
    width: 85%;
}




.EventsGroupsNav>li {
    list-style: none;
    margin-left: 10px;
    padding-left: 0%;
    margin-right: 15px;
}

.EventsGroupsLinks {
    font-size: 30px;
    margin-left: 0%;
    padding-left: 0%;
} */

.EventsGroupsNav ul {
    margin: 0;
    padding: 0;
    display: flex;
    flex-direction: row;
    flex: auto;
    justify-content: flex-start;
    justify-items: flex-start;
    align-items: flex-start;
}


.EventsGroupsNav li {
    list-style: none;
    margin: 0;
    padding: 0;
    display: inline-flex;
    font-weight: 300;

}

.EventsGroupsLinks {
    font-size: 1.9rem;
    font-weight: 200;

    color: rgb(117, 117, 117);
    text-decoration: none;
}


.underlineText {
    text-decoration: underline;
}

.EventsGroupsLinks:first-child {
    margin-right: .5em;
}
