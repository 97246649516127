@import url("https://fonts.googleapis.com/css2?family=Open+Sans&display=swap");

.hidden {
  display: none;
}

.mainDiv {
  position: fixed;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  background-color: rgb(0, 0, 0, 0.5);
  z-index: 6;
}

div.signUpComponent {
  padding-top: 10px;
  display: flex;
  flex-direction: column;
  font-family: "Open Sans", sans-serif;
  font-weight: 590;
  align-items: center;
  border: 1px solid lightgrey;
  margin: auto auto auto auto;
  width: 390px;
  border-radius: 10px;
  background-color: white;
  height: 90vh;
  overflow-y: scroll;
  overflow-x: hidden;
  position: fixed;
}

.signUpComponent::-webkit-scrollbar {
  width: 12px;
  background-color: rgba(186, 186, 186, 0.301);
}

.signUpComponent::-webkit-scrollbar-thumb {
  background-color: rgb(186, 186, 186);
  border-radius: 8px;
}

.signUpComponent::-webkit-scrollbar-thumb:hover {
  background-color: rgba(186, 186, 186, 0.758);
}

#loginComponent {
  height: 45vh;
}

.xButtonDiv {
  width: 100%;
  margin: 0 20px 0 0;
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
}

.xButton {
  font-size: 1.5rem;
  color: black;
  background-color: white;
  border: none;
}

.errors {
  width: 250px;
}

.loginForm {
  display: inline-flex;
  flex-direction: column;
  color: black;
  background-color: white;
}

.formBox {
  display: inline-flex;
  flex-direction: column;
  margin: 5px;
  background-color: white;
  width: 300px;
}

.label {
  background-color: white;
  margin: 5px 0 5px 0;
}

.input {
  border: 1px solid lightgray;
  border-radius: 5px;
  margin-right: 5px;
  background-color: white;
  height: 25px;
}

.submitButton {
  height: 40px;
  margin-top: 5px;
  border: 1px solid orangered;
  border-radius: 7px;
  margin: 10px 0 10px 0;
  font-weight: bold;
  color: white;

  background-color: orangered;
  font-size: 17px;
  z-index: 2;
}
