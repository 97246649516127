html,
body {
  margin: 0;
  padding: 0;
  overflow-x: hidden;
}

.mainGroupDetailsDiv {
  width: 100vw;
  margin: 0 auto 0 auto;
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 0;
  position: absolute;
  top: 70px;
}

.eventDetailsTopSectionContainer {
  width: 90vw;
  margin-top: 15px;
  padding-left: 5px;
  margin-bottom: 0px;
}

.eventDetailsTopSection h1 {
  margin-top: 0px;
  margin-bottom: 0px;
  font-weight: 300;
}

.eventDetailsTopSection h2 {
  margin-top: 0px;
  font-size: 18px;
  color: rgb(103, 100, 100);
  font-weight: 300;
}

.eventDetailsGreySection {
  width: 100%;
  background-color: #f6f7f8;
  margin: 0;
  display: flex;
  flex-direction: column;
  align-items: center;
  min-height: 80vh;
}

.eventDetailsGreySectionTopHalf {
  width: 90vw;
  margin-top: 30px;
  margin-bottom: 20px;
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  /* background-color: bisque; */
}

.eventDetailsImage {
  width: 45vw;
  height: fit-content;
  min-width: 415px;
  max-height: 475px;
  max-width: 751px;
  object-fit: cover;
  margin-right: 15px;
  margin-bottom: 15px;
  border-radius: 5px;
}

.eventDetailsRightSection {
  width: fit-content;
  /* background-color: aqua; */
}

.eventDetailsGroupDiv {
  display: flex;
  flex-direction: row;
  align-items: center;
  height: fit-content;

  /* flex: auto; */
  border-radius: 5px;

  flex-direction: row;
  justify-content: flex-start;
  /* width: auto; */
  /* flex: auto; */
  background-color: white;
  padding: 10px 5px 10px 5px;
  margin-bottom: 10px;
  height: min-content;
  width: auto;
  margin-right: 0;
}

.eventDetailsGroupDiv:hover {
  cursor: pointer;
}

.eventDetailsGroupImage {
  width: 30%;
  max-height: 100px;
  min-width: 91px;
  max-width: 120px;
  object-fit: cover;
  border-radius: 5px;
  margin: 0 1rem 0 1rem;
}

.eventDetailsGroupDetailsDiv {
  /* background-color: darkolivegreen; */
  margin-right: 0;

  width: fit-content;
}

.eventDetailsGroupDiv h3 {
  margin-bottom: 2px;
  margin-right: 0px;
  padding-right: 0;
  font-weight: 500;
  font-size: 1.1rem;
}

.eventDetailsGroupDiv h4 {
  color: rgb(82, 82, 82);
  margin-top: 1px;
  font-size: 0.95rem;
  font-weight: 300;
}

.eventDetailsRightOfPictureInfo {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  height: min-content;
  width: auto;
  background-color: white;
  border-radius: 5px;
  padding: 10px 10px 10px 15px;

  padding-left: 15px;
}

.eventDetailsRightOfPictureInfo h4 {
  font-size: 15px;
  margin: 10px 0 10px 0;
  font-weight: 300;
}

.eventDetailsRightOfPictureInfo h5 {
  font-size: 13.5px;
  font-weight: 300;
}

.eventDetailsRightOfPictureInfo i {
  font-size: 25px;
}

.timeDiv {
  display: flex;
  flex-direction: row;
  justify-content: space-around;

  flex: auto;
  align-content: center;
  align-items: center;
  margin-bottom: 5px;
}

.timeDiv i {
  size: 15px;

  margin-right: 10px;
  color: rgb(82, 82, 82);
}

.timeSubDiv {
  display: flex;
  margin: 0;
  /* max-width: auto;
    width: fit-content; */

  flex-direction: row;
  flex-wrap: wrap;
}

.startEndDiv {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  width: 185px;
  margin-right: 1rem;
  padding-right: 0;
}

.timeSubDiv h4 {
  margin-right: 0.5rem;
  color: rgb(82, 82, 82);
  margin-bottom: 0;
  margin-top: 0;
}

.timeSubDiv h5 {
  margin: 0 1rem 0 0;
  padding-top: 1.8px;
  margin-right: 0;
  padding-right: 0;
}

.moneyDiv {
  display: flex;
  flex-direction: row;
  align-items: center;
  color: rgb(82, 82, 82);
  margin: 0;
  padding: 0;
}

.moneyDiv i {
  size: 15px;

  margin-right: 15px;
  margin-left: 5px;
  color: rgb(82, 82, 82);
}

.locationDiv {
  display: flex;
  flex-direction: row;
  align-items: center;
  color: rgb(82, 82, 82);
}

.locationDiv i {
  margin-right: 15px;
  margin-left: 2px;
}

.locationDiv i:hover {
  cursor: pointer;
}

.typeDeleteDiv {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
}

.deleteUpdateDiv {
  margin: 0.75rem auto 0 auto;
  display: flex;
  flex-wrap: wrap;
  flex-direction: row;
  justify-content: space-between;
  width: auto;
}

div.deleteUpdateDiv button {
  background-color: #0d6d6d;
  border-color: #0d6d6d;
  width: 45%;
  color: white;
  margin-top: 0.5rem;
  border-radius: 5px;
  padding: 0.5rem 0;
  font-weight: 500;
  border: none;
}

div.deleteUpdateDiv button:hover {
  cursor: pointer;
  background-color: #0d6d6dbe;
  border-color: #0d6d6dbe;
}

div.deleteUpdateDiv :first-child {
  margin-right: 0;
  /* margin-top: 0.75rem; */
  background-color: #b51616;
  border-color: #b51616;
}

.deleteUpdateDiv :first-child:hover {
  background-color: #b51616cf;
  border-color: #b51616cf;
}

.greySectionBottomHalf {
  width: 90vw;
  padding-bottom: 2rem;
}

.greySectionBottomHalf h2 {
  font-weight: 300;
  margin-bottom: 1rem;
}

@media only screen and (max-width: 750px) {
  .mainGroupDetailsDiv {
    max-width: 100%;
    overflow-x: hidden;
  }

  .eventDetailsTopSectionContainer {
    /* background-color: darkorange; */
    width: 97vw;
  }

  .eventDetailsTopSection {
    /* background-color: lawngreen; */
  }

  .belowEventDetailsBackButton {
    text-align: center;
  }

  .eventDetailsGreySection {
    width: 100vw;
    padding-top: 0;
    display: flex;
    flex-direction: column;
    justify-items: flex-start;
    justify-content: flex-start;
  }

  .eventDetailsGreySectionTopHalf {
    flex-direction: column;
    align-items: center;
    height: fit-content;
    justify-items: flex-start;
    padding-top: 0;
    margin-top: 0;
  }

  /* .eventDetailsImage {
        margin-right: 0;
        margin-top: 0;
        margin-bottom: 0;
        min-width: none;
        min-height: none;
        width: 50vw;
    } */

  #eventDetailsImageId.eventDetailsImage {
    margin-right: 0;
    margin-top: 0;
    margin-bottom: none;
    min-width: none;
    min-height: none;
    width: 100vw;
  }

  .eventDetailsRightSection {
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 95vw;
  }

  .eventDetailsGroupDiv {
    border-radius: 0;
    display: flex;
    flex-direction: row;
    width: 100%;
    padding-left: 10px;
    padding-right: 11px;
  }

  .eventDetailsGroupImage {
  }

  .eventDetailsGroupDetailsDiv {
    width: 100%;
    height: fit-content;
  }

  .eventDetailsRightOfPictureInfo {
    border-radius: 0;
    width: 100%;
    margin-top: 0.3rem;
  }

  .timeSubDiv {
    width: fit-content;
  }

  .startEndDiv {
  }

  #eventDetailsUpdateButton {
    width: 90vw;
    margin: 0.55rem auto;
  }

  #eventDetailsDeleteButton {
    width: 90vw;
    margin: 0.5rem auto;
  }
}
