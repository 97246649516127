#newGroupMainDiv {
  position: absolute;
  top: 65px;
  /* background-color: aqua; */
  width: 99vw;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.newGroupSubDiv {
  padding-top: 25px;
  width: 90vw;
  padding-bottom: 10px;
}

.newGroupSubDiv textarea {
  width: 27rem;
  height: 10rem;
  background-color: rgb(232, 240, 254);
  border: 1px solid #333;
}

.newGroupSubDiv input[type="text"] {
  background-color: rgb(232, 240, 254);
  margin-right: 0.75rem;
  margin-bottom: 0.5rem;
  width: 25rem;
  border: 1px solid black;
}

.newGroupSubDiv select {
  border: 1px solid black;
  background-color: rgb(232, 240, 254);
}

#newGroupHeader {
  color: rgb(0, 82, 94);
  font-size: 1.2rem;
  margin-bottom: 1px;
}

#newGroupHeader + h3 {
  border-bottom: solid black 1px;
  margin-top: 0;
  padding-bottom: 10px;
}

.newGroupSubDiv h3 {
  font-size: 1.3rem;
  margin-bottom: 0.25rem;
  margin-top: 0;
  font-weight: 500;
}

.newGroupSubDiv h5 {
  margin-top: 0;
  font-size: 0.9rem;
  margin-bottom: 10px;
  font-weight: 500;
}

.newGroupSubDiv ol {
  margin-top: 0;
  margin-left: 0;
  padding-left: 1rem;
}

.newGroupSubDiv li {
  font-size: 0.85rem;
  font-weight: 500;
}

.newGroupSubDiv div {
  padding-bottom: 15px;
  margin-bottom: 1rem;

  border-bottom: solid black 1px;
}

#finalStepsDiv {
  margin-bottom: 0.5rem;
}

#finalStepsDiv div {
  border-bottom: none;
  margin: 0;
}

#finalStepsDiv h5 {
  margin-bottom: 5px;
  padding-bottom: 0;
  margin-top: 0;
}

.newGroupSubmitButtom {
  background-color: rgb(0, 82, 94);
  /* box-shadow: 1px 2px 2px black; */
  border: none;
  border-radius: 5px;
  color: white;
  padding: 10px 15px;
  font-size: 1rem;
  font-weight: 500;
  margin: 0.5rem 0 1rem 0;
}

.newGroupSubmitButtomDisabled {
  background-color: rgb(0, 82, 94);
  /* box-shadow: 1px 2px 2px black; */
  border: none;
  border-radius: 5px;
  opacity: 0.5;
  color: white;
  padding: 10px 15px;
  font-size: 1rem;
  font-weight: 500;
  margin: 0.5rem 0 1rem 0;
  pointer-events: none;
}

.errors ul {
  margin-left: 0;
  padding-left: 0;
}

.errors li {
  color: red;
}
