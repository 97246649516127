div.uploads {
  display: flex;
  flex-direction: column;
  align-items: center;
  margin: 0rem auto;
  border: 3px dashed #33333361;
  padding: 1.5rem;
  /* outline: red thin dotted; */
}

div.uploads img.uploads {
  height: 200px;
  width: 200px;
  object-fit: contain;
  border-radius: 8px;
  margin: 0;
  padding: 0;
}

div.uploads ul {
  margin-top: 0;
  padding-top: 0;
  padding-left: 0;
  padding: 0 2rem;
}

div.uploads li {
  margin-top: 0;
  padding-top: 0;
}

div.actions {
  display: flex;
  flex-wrap: wrap;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  width: 100%;
}

div#dragDropFiles button {
  padding: 0.25rem 2rem;
  width: 25%;
  font: medium;
  margin: 0.25rem 0.5rem;
  background-color: #0d6d6d;
  border: 1px solid #0d6d6d;
  color: white;
  padding: 0.5rem 2rem;
  border-radius: 5px;
}

div#dragDropFiles button:hover {
  cursor: pointer;
  background-color: #0d6d6dbe;
  border-color: #0d6d6dbe;
}

div#dragDropFiles button.cancelButton {
  background-color: #b51616;
  border-color: #b51616;
}

div#dragDropFiles button.cancelButton:hover {
  background-color: #b51616cf;
  border-color: #b51616cf;
}

div.dropZone {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  height: 250px;
  padding: 2rem;
  border: 3px dashed #33333361;
  border-radius: 8px;
  transition: all 0.3s ease-in-out;
}

div.dropZone.dragOver {
  /* border-color: #3bb18f; */
  scale: 0.98;
  background-color: #c0e6d748;
}

div.dropZone h1 {
  font-size: 1.3rem;
  font-weight: 500;
  color: #333;
  margin-top: 0;
  /* padding-bottom: 0; */
}

@media only screen and (max-width: 750px) {
  div.actions {
    flex-direction: column;
  }

  div#dragDropFiles button {
    width: 100%;
    margin: 0.55rem auto;
  }
}
