.containerHome {
  display: flex;
  flex-direction: column;
  align-items: center;
  height: auto;
  width: 100vw;
  padding: auto;
  margin: auto;
  top: 65px;
  position: absolute;
  /* background-color: antiquewhite; */
}

.WelcomeMessage {
  margin: 0;
  font-size: 1.75rem;
  font-weight: 300;
  color: #333;
}

.welcomeMessageDisabled {
  display: none;
}

.homeTopSection {
  /* background-color: blue; */
  display: flex;
  flex-direction: row;
  justify-content: center;
  justify-items: center;
  align-items: center;
  padding: 10px 50px 10px 50px;
  margin: 0 auto auto auto;
  max-height: 794px;
  max-width: 1618px;
}

.homePageImage {
  width: 35cqw;
  max-width: 500px;
  margin-left: 40px;
}

.homeTopSection h1 {
  margin-right: 0 40px 0 0;
  max-width: 600px;
  font-size: 2rem;
  font-weight: 300;
}

.homeTopSection p {
  max-width: 600px;
}

.homeMiddleSection {
  /* background-color: rgb(0, 130, 148); */
  display: inline-flex;
  flex-direction: column;
  align-items: center;
  justify-items: center;
  justify-content: center;
  margin-bottom: 15px;
  padding: 0px 40px 0px 40px;
}

div.homeMiddleSection h2 {
  margin-top: 4rem;
  margin-bottom: 1rem;
  font-weight: 300;
  font-size: 2.25rem;
  color: #333;

  @media screen and (max-width: 580px) {
    text-align: center;
    margin-bottom: 2rem;
  }
}

div.homeMiddleSection p {
  text-align: center;
  margin: 0px;
  padding: 0px;
  width: auto;
  color: #333;
  font-size: 1.25rem;
  margin-bottom: 1rem;
}

.homeThirdSection {
  display: inline-flex;
  flex-direction: row;
  justify-content: center;
  align-items: flex-start;
  /* background-color: violet; */
  padding: 0 30px 0 30px;
  margin: 15px 0px 15px 0px;
}

.homeThirdSectionDiv {
  margin: 0px 20px 0px 20px;
  max-width: 300px;
  text-align: center;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

#homeThirdSectionDivDisabled {
  margin: 0px 20px 0px 20px;
  color: darkgray;
  max-width: 300px;
  text-align: center;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

#homeThirdSectionDivDisabled i {
  color: darkgray;
}

#homeThirdSectionDivDisabled a {
  color: darkgray;
  cursor: default;
}

#homeThirdSectionDivDisabled a:hover {
  text-decoration: none;
  pointer-events: none;
  cursor: default;
}

#homeThirdSectionDivDisabled a:hover::before {
  width: 0%;
}

.homeThirdSection i {
  font-size: 70px;
  margin-top: 10px;
  color: rgb(0, 130, 148);
}

.homePageSection3Links {
  text-decoration: none;
  margin: 10px 0px 0px 0px;
  color: black;
  font-weight: bold;
  position: relative;
}

.homePageSection3Links::before {
  content: "";
  position: absolute;
  left: 0;
  bottom: -5px;
  width: 0;
  height: 2px;
  background-color: black;
  transition: width 0.3s ease;
}

.homePageSection3Links:hover::before {
  width: 100%;
}

.homeFourthSection {
  display: flex;
  justify-content: center;
  align-items: center;
  margin: 0;
  padding-top: 0px;
  padding-right: 10px;
}

.joinButton {
  list-style: none;
  font-size: 17px;
  font-family: "Open Sans", sans-serif;
  text-decoration: none;
  margin: 15px 13px 15px 13px;
  background-color: rgb(0, 130, 148);
  color: white;
  padding: 9px 20px 9px 20px;
  border: solid 1px rgb(0, 130, 148);
  border-radius: 5px;
  font-weight: 500;
  transition: all 0.3s ease;
}

.joinButtonHidden {
  display: none;
}

.joinButton:hover {
  opacity: 0.85;
}

.homePageFooter {
  margin: 3rem 0px 0px 0px;
  width: 100%;
  min-height: 6rem;
  text-align: center;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  background-color: none;
  background-color: #008294;
  border-top-left-radius: 40%;
  border-top-right-radius: 40%;
}

.homePageFooterLinks {
  text-decoration: none;
  color: white;
  padding: 0 0.4rem 0.4rem 0.4rem;
  font-size: 2rem;
  margin: 0.5rem;
  /* border-bottom: orangered solid 1px; */
}

.wellfound {
  font-weight: 600;
}

@media only screen and (max-width: 580px) {
  .homeTopSection {
    flex-direction: column;
  }

  .homeThirdSection {
    flex-direction: column;
  }
}
