html,
body {
    padding: 0;
    margin: 0;
}

.seeAllGroupsMainDiv {
    margin: 0;
    padding: 0;
    display: flex;
    flex-direction: column;
    align-items: center;
    min-width: 350px;
    position: absolute;
    top: 65px;
    /* background-color: aqua; */
    overflow: hidden;
    width: 100%;
}

.EventsGroupsNav {
    margin: 40px 0 5px 0;
    padding: 5px 0 5px 0;
    /* background-color: rgb(98, 0, 255); */
    width: 80%;
    max-width: 1300px;
}

#underlineEvents {
    pointer-events: none;
    text-decoration: underline;
    color: rgb(0, 82, 94);
}

.belowEventsGroupsNav {
    /* background-color: beige; */
    width: 80%;
    max-width: 1300px;
    margin-top: 0;
}

.allEventsButtons_subHeaderDiv {
    max-width: 1300px;
    border-bottom: 1px solid lightgray;
    margin-bottom: 1rem;
    padding-bottom: 1rem;
}

.EventsInMeetup {
    font-size: 1.4rem;
    margin-top: .2rem;
    font-weight: lighter;
    padding-bottom: .1rem;
    color: rgb(117, 117, 117);

}

.timeEventQueryButtonClick {
    background-color: #008294;
    border: #008294 solid 2px;
    border-radius: 20px;
    color: white;
    padding: 5px 10px 5px 10px;
    font-weight: 500;
    margin-right: 10px;
    margin-bottom: 5px;
}

.timeEventQueryButtonNotClicked {
    background-color: white;
    border: #008294 solid 2px;
    border-radius: 20px;
    color: darkgray;
    padding: 5px 10px 5px 10px;
    font-weight: 500;
    margin-right: 10px;
    margin-bottom: 5px;
}

.eventQueryButtonNotClicked {
    background-color: #F6F7F8;
    border: #F6F7F8 solid 1px;
    border-radius: 20px;
    color: darkgray;
    padding: 5px 10px 5px 10px;
    font-weight: 500;
    margin-right: 10px;
    margin-bottom: 5px;
}

.eventQueryButtonNotClicked:hover {
    opacity: .85;
}

.eventQueryButtonClick {
    background-color: orangered;
    border: orangered solid 1px;
    color: white;
    border-radius: 20px;
    padding: 5px 10px 5px 10px;
    font-weight: 500;
    margin-right: 10px;
    margin-bottom: 5px;
}

.eventQueryButtonClick:hover {
    opacity: .85;
}

#eventsSearch {
    border-radius: 15px;
    border: lightgray solid .75px;
    padding: 5px 10px 5px 10px;
    text-align: center;
}

.GroupsInMeetup {
    /* background-color: darkviolet; */
    color: rgb(153, 153, 153);
    font-size: 1.5rem;
    margin-top: .5rem;
    font-weight: 600;
    padding-bottom: 1.5rem;
    border-bottom: 1px solid lightgray;

}

.allEventsContainer {
    /* background-color: cadetblue; */
    border-bottom: 1px solid lightgray;
    margin-bottom: 1.5rem;
}

.allEventsContainer:hover {
    cursor: pointer;
}

.groupContainer {
    /* background-color: cornflowerblue; */
    display: flex;
    flex-direction: row;
    align-items: flex-start;
}


.allGroupsImg {
    width: 40%;
    min-width: 255px;
    min-height: 150px;
    max-width: 400px;
    height: fit-content;
    object-fit: cover;
    margin-right: 1rem;
    border-radius: 5px;
}

.groupContainerDetails {
    /* background-color: brown; */
    width: 60%;
    flex: auto;
}

#allEventsEventDate {
    color: rgb(153, 153, 153);
    font-size: 1rem;
    font-weight: 300;

}

#allEventsName {
    font-size: 1.2rem;
    font-weight: 300;
    margin-bottom: .4rem;
    color: black;
}

#allEventsCity {
    color: rgb(135, 116, 87);
    margin-top: 0;
    font-size: 1rem;
    font-weight: 300;

}

.seeAllEventsType {
    margin: .5rem 0 0 0;
    font-size: 1rem;
    color: darkgray;
    font-weight: 300;
}

.groupContainerDetails h3 {
    margin-bottom: .25rem;
}

.groupContainerDetails h4 {
    margin-top: 0;
}

.EventsPrivacyDiv {
    /* background-color: green; */
    display: flex;
    flex-direction: row;
}

.EventsPrivacyDiv h4 {
    color: darkgray;
    margin-right: .25rem;
}




@media screen and (max-width: 607px) {
    .seeAllGroupsMainDiv {
        /* background-color: lightgreen; */
    }

    .allEventsContainer {
        width: 100%;

        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        align-content: center;
    }

    .groupContainer {
        width: 100%;

        align-items: center;
        flex-direction: column-reverse;
    }

    .allGroupsImg {
        width: 100%;
        max-width: none;
        max-height: none;
        margin: .5rem 0 0 0;
    }

    .groupContainerDetails {
        width: 100%;
    }


}
