.mainNavDiv {
  min-width: 200px;
  border-bottom: lightgray solid 1px;
  margin-top: 0;
  padding: 0.5rem 1rem 0.5rem 1rem;
  position: fixed;
  top: 0;
  right: 0;
  left: 0;
  background-color: white;
  z-index: 5;
}

.navUl {
  display: flex;
  flex-direction: row;
  align-content: center;
  align-items: center;
  justify-content: space-between;
  min-width: 100px;
  padding: 0px 1px 0px 1px;
  margin-bottom: 4px;
  margin-top: 0;
}

.homeButton {
  list-style: none;
  height: 50px;
  font-family: "Open Sans", sans-serif;
  text-decoration: none;
  font-weight: bold;
  font-size: 40px;
  color: orangered;
}

.homeButtonLi {
  list-style: none;
}

.navLi {
  list-style: none;
  font-family: "Open Sans", sans-serif;
  text-decoration: none;
  padding: 0;
}

.profileIcon {
  margin: 0;
  color: orangered;
  border: 1px white solid;
  border-radius: 15px;
  padding: 0;
  font-size: 35px;
  margin-bottom: 0;
}

.profileButton {
  border: none;
  margin: 0;
  padding-top: 15px;
  background-color: white;
}

.profileButtonUl {
  background-color: none;
  list-style: none;
}

.profileButtonComponent {
  background-color: none;
}

.hidden {
  display: none;
}

.profile-dropdown {
  position: fixed;
  right: 15px;
  top: 40px;
  bottom: 0;
  border: lightgray 1px solid;
  background-color: white;
  height: fit-content;
  width: fit-content;
  padding: 10px 0 2px 0;
  margin-top: 35px;
  z-index: 20;
}

.sessionLinksUl {
  list-style: none;
}

.loginSignup {
  list-style: none;
  font-size: 17px;
  font-family: "Open Sans", sans-serif;
  text-decoration: none;
  margin-left: 13px;
  color: black;
  font-weight: 500;
  background-color: white;
  border: none;
}

button {
  cursor: pointer;
}

.navDropLi {
  height: fit-content;
  font-family: "Open Sans", sans-serif;
  list-style: none;
  border-radius: 7px;
  font-weight: 500;
  color: white;
  color: black;
  font-size: 13px;
  width: 120px;
  text-align: center;
}

.navBioInfo {
  color: lightslategray;
}

.logoutButton {
  border: none;
  background: none;
  text-align: center;
  width: 100%;
  padding: 2px 0 2px 0;
  margin: 3px 0 0 0;
  border-top: 1px solid lightgray;
  transition: color 0.3s ease;
}

.logoutButton:hover {
  color: deepskyblue;
}

.underline-animation {
  position: relative;
  margin-left: 0;
  margin-right: 1rem;
  padding: 0;
}

.underline-animation::before {
  content: "";
  position: absolute;
  left: 0;
  bottom: -5px;
  width: 0;
  height: 2px;
  background-color: orangered;
  transition: width 0.3s ease;
}

.underline-animation:hover::before {
  width: 100%;
}
