html,
body {
    padding: 0;
    margin: 0;
}

.seeAllGroupsMainDiv {
    margin: 0;
    padding: 0;
    display: flex;
    flex-direction: column;
    align-items: center;
    min-width: 350px;
    position: absolute;
    top: 65px;
    /* background-color: aqua; */
    overflow: hidden;
}

.EventsGroupsNav {
    margin: 40px 0 5px 0;
    padding: 5px 0 5px 0;
    /* background-color: aqua; */
    width: 80%;
    max-width: 1300px;

}

#underlineGroups {
    pointer-events: none;
    text-decoration: underline;
    color: rgb(0, 82, 94);
}

.belowEventsGroupsNav {
    /* background-color: beige; */
    width: 80%;
    max-width: 1300px;
    margin-top: 0;
}

#GroupsInMeetup {
    font-size: 1.4rem;
    margin-top: .2rem;
    font-weight: lighter;
    padding-bottom: 1rem;
    border-bottom: 1px solid lightgray;
    color: rgb(117, 117, 117);
}

.allEventsContainer {
    /* background-color: cadetblue; */
    padding-bottom: 15px;
    border-bottom: 1px solid lightgray;
    margin-bottom: 1.5rem;
}

.allEventsContainer:hover {
    cursor: pointer;
}

.groupContainer {
    /* background-color: cornflowerblue; */
    display: flex;
    flex-direction: row;
    align-items: flex-start;
}


.allGroupsImg {
    width: 40%;
    min-width: 255px;
    min-height: 150px;
    max-width: 400px;
    height: fit-content;
    object-fit: cover;
    margin-right: 1rem;
    border-radius: 5px;
}

.groupContainerDetails {
    /* background-color: brown; */
    width: 60%;
    flex: auto;

}

.groupContainerDetails h3 {
    margin-bottom: .25rem;
    font-size: 1.2rem;
    font-weight: 300;
}

.groupContainerDetails h4 {
    margin-top: 0;
    font-size: 1rem;
    color: darkgray;
    font-weight: 300;

}

#allGroupsCity {
    margin-top: .5rem;
    margin-bottom: .5rem;
    padding-top: 0;
    width: fit-content;
    color: rgb(135, 116, 87)
}

.EventsPrivacyDiv {
    /* background-color: green; */
    display: flex;
    flex-direction: row;
}

.EventsPrivacyDiv h4 {
    margin-right: .25rem;
    margin-top: 0;
    padding-top: 0;
    color: rgb(117, 117, 117);
    font-weight: lighter;
}

.pDiv {
    display: -webkit-box;
    -webkit-line-clamp: 3;
    -webkit-box-orient: vertical;
    overflow: hidden;
    text-overflow: ellipsis;
    margin-bottom: 0;
    padding-bottom: 0;
}

.pDiv p {
    margin-bottom: 0;
    padding-bottom: 0;
}

@media screen and (max-width: 607px) {
    .seeAllGroupsMainDiv {
        /* background-color: lightgreen; */
    }

    .allEventsContainer {
        width: 100%;

        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        align-content: center;
    }

    .groupContainer {
        width: 100%;

        align-items: center;
        flex-direction: column-reverse;
    }

    .allGroupsImg {
        width: 100%;
        max-width: none;
        max-height: none;
        margin-right: 0;
    }

    .groupContainerDetails {
        width: 100%;
    }

    .pDiv {
        /* padding-left: 1rem; */
    }
}

/* .seeAllGroupsMainDiv {
    display: flex;
    flex-direction: column;
    width: auto;
    background-color: darksalmon;
    padding: 0;
}

.seeAllh2 {
    background-color: beige;
    display: flex;
    /* flex-direction: row;
    flex: auto;
    justify-content: flex-start; */
/* align-items: flex-start;
} */
/*
.GroupsInMeetup {
    margin-left: 0;
    padding-left: 15px;
}



.allEventsContainer:hover {
    cursor: pointer;

}

.allEventsContainer {
    display: flex;
    flex: auto;
    width: 100%;
    height: fit-content;
    background-color: darkseagreen;
    margin: 0;
    padding: 0;
}

.groupContainer {
    display: flex;
    flex-direction: row;
    flex: auto;
    justify-content: flex-start;
    align-items: flex-start;
    width: 80vw;
    margin-bottom: 10px;
    background-color: aqua;
}

.allGroupsImg {
    height: 150px;
    width: 255px;
    object-fit: none;
    border-radius: 10px;
    object-fit: cover;
}

.groupContainerDetails {
    display: flex;
    flex-direction: column;
    justify-items: flex-start;
    justify-content: flex-start;
}

.groupContainerDetails h3 {
    margin-bottom: 2px;
    margin-top: 0;
    font-size: 19px;
}


.groupContainerDetails h3+h4 {
    font-size: 15px;
    margin-top: 2px;
    margin-bottom: 3px;
    color: gray;
}

.groupContainerDetails p {}

.EventsPrivacyDiv {
    display: flex;
    flex-direction: row;
    justify-items: flex-start;
    color: gray;
}

.EventsPrivacyDiv h4 {
    /* margin-right: 5px;
    padding-right: 10px; */
/* margin-top: 0;
}

.EventsPrivacyDiv :first-child {
    border-right: lightgray 1px solid;
    margin-right: 10px;
}

.EventsPrivacyDiv h4 {

    font-size: 15px;
}

.pDiv {
    width: auto;
}

.allEventsContainer p {
    background-color: burlywood;

}  */
