/* TODO Add site wide styles */
@import url("https://fonts.googleapis.com/css2?family=Open+Sans&display=swap");

* {
  font-family: "Open Sans", sans-serif;
}

/* .mainDiv {} */
.main {
  min-width: 600px;
  margin: 0;
  padding: 0;
}

.body {
  margin: 0;
  padding: 0;
}

.mainHeader {
  align-self: flex-start;
}

*::-webkit-scrollbar {
  width: 12px;
  background-color: rgba(186, 186, 186, 0.301);
}

*::-webkit-scrollbar-thumb {
  background-color: rgb(186, 186, 186);
  border-radius: 8px;
}

*::-webkit-scrollbar-thumb:hover {
  background-color: rgba(186, 186, 186, 0.758);
}
