section.landingTopSection {
  width: 100%;
  height: 90vh;
  color: white;
  padding: 1rem 1rem 0 1rem;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  position: relative;
  /* background-color: white; */
  /* outline: red solid 1px; */
  overflow: hidden;
}

.custom-shape-divider-bottom-1690059328 {
  position: absolute;
  bottom: 0px;
  left: 0;
  width: 100%;
  overflow: hidden;
  line-height: 0;
  transform: rotate(180deg);
}

.custom-shape-divider-bottom-1690059328 svg {
  position: relative;
  display: block;
  width: calc(100% + 1.3px);
  height: 188px;
  transform: rotateY(180deg);
}

.custom-shape-divider-bottom-1690059328 .shape-fill {
  fill: rgb(0, 130, 148);
}

div.landingHeader {
  padding: 0rem 2.5rem 5rem 2.5rem;
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  border-radius: 8px;
  background-color: rgba(255, 255, 255, 0.3);
  backdrop-filter: blur(5px);
  position: relative;
  overflow: hidden;
  /* outline: blue dotted 1px; */
}

.WelcomeMessage {
  margin: 0;
  font-size: 1.75rem;
  font-weight: 300;
  color: #333;
}

section.landingTopSection h1 {
  margin-right: 0 40px 0 0;
  max-width: 600px;
  font-size: 2.75rem;
  font-weight: 300;
  color: #333;
}

.homePageImage {
  width: 37cqw;
  max-width: 500px;
  margin-left: 0;
}

.blob {
  position: absolute;
  width: 500px;
  height: 500px;
  background: linear-gradient(
    180deg,
    orangered 20.77%,
    rgba(255, 68, 0, 0.833) 100%
  );
  mix-blend-mode: color-dodge;
  animation: move 40s infinite alternate;
  z-index: -20;
}

@keyframes move {
  from {
    transform: translate(-200px, -300px) rotate(-90deg);
    border-radius: 24% 76% 35% 65% / 27% 36% 64% 73%;
  }

  to {
    transform: translate(400px, 400px) rotate(-10deg);
    border-radius: 76% 24% 33% 67% / 68% 55% 45% 32%;
  }
}

@media only screen and (max-width: 580px) {
  .homePageImage {
    display: none;
  }
}
