#updateEventMainDiv {
  position: absolute;
  top: 65px;
  /* background-color: aqua; */
  width: 99vw;
  display: flex;
  flex-direction: column;
  align-items: center;
}

div#updateEventMainDiv h1 {
  color: rgb(0, 82, 94);
  margin-top: 0.5rem;
  margin-bottom: 0;
  font-weight: 500;
  padding-bottom: 0.5rem;
  font-size: 1.35rem;
  border-bottom: solid black 1px;
}

input[type="number"] {
  width: 4em;
}
