.detailsHiddenMainDiv {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  position: absolute;
  top: 80px;
  left: 0;
  width: 100%;
  min-height: 85vh;
}

.detailsHiddenSubDiv {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  max-width: 500px;
  padding: 2rem;
  text-align: center;
  border-radius: 1rem;
  background-color: #ffffff;
  box-shadow: rgba(149, 157, 165, 0.2) 0px 8px 24px;
}

.detailsHiddenSubDiv h1 {
  margin-bottom: 1rem;
  font-size: 2.5rem;
  font-weight: 700;
  color: #333333;
}

.detailsHiddenSubDiv h2 {
  margin-bottom: 2rem;
  font-size: 1.5rem;
  font-weight: 400;
  color: #666666;
}

.eventDetailsHiddenGroupDiv {
  display: flex;
  width: 100%;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  margin-bottom: 2rem;
}

.eventDetailsHiddenGroupDiv:hover {
  cursor: pointer;
}

.eventDetailsHiddenGroupImage {
  width: 5rem;
  height: 5rem;
  border-radius: 50%;
  margin-right: 1rem;
}

.eventDetailsHiddenGroupDetailsDiv {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
}

.eventDetailsHiddenGroupDetailsDiv h3 {
  margin-bottom: 0.5rem;
  font-size: 1.25rem;
  font-weight: 200;
  color: #333333;
}

.eventDetailsHiddenGroupDetailsDiv h4 {
  font-size: 1rem;
  font-weight: 100;
  color: #666666;
}

.lock {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
}

.lock svg {
  color: #666666;
}
