#newEventMainDiv {
  position: absolute;
  top: 65px;
  /* background-color: aqua; */
  width: 99vw;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.newEventSubDiv {
  padding-top: 25px;
  padding-bottom: 10px;
  width: 90vw;
}

#newEventHeader {
  color: rgb(0, 82, 94);
  font-size: 1.5rem;
  margin-bottom: 0.75rem;
  padding-bottom: 0.5rem;
  font-weight: 500;
  border-bottom: solid black 1px;
}

.newEventForm {
  margin-top: 0;
  padding-top: 0;
}

#newEventName {
  margin-top: 0.5rem;
}

.newEventSubDiv h3 {
  font-size: 1.3rem;
  margin-bottom: 0.25rem;
  margin-top: 0;
}

.newEventSubDiv h4 {
  margin-top: 1rem;
  font-size: 0.9rem;
  margin-bottom: 10px;
  font-weight: 500;
}

.newEventSubDiv ol {
  margin-top: 0;
  margin-left: 0;
  padding-left: 1rem;
}

.newEventSubDiv li {
  font-size: 0.85rem;
  font-weight: 500;
}

.newEventSubDiv input[type="text"] {
  background-color: rgb(232, 240, 254);
  margin-right: 0.75rem;
  margin-bottom: 0.5rem;
  width: 25rem;
  border: 1px solid black;
}

.newEventSubDiv textarea {
  width: 25rem;
  height: 10rem;
  background-color: rgb(232, 240, 254);
  border: 1px solid black;
}

.newEventSubDiv select {
  border: 1px solid black;
  background-color: rgb(232, 240, 254);
}

.newEventSubDiv div {
  padding-bottom: 15px;
  margin-bottom: 1rem;
  border-bottom: solid black 1px;
}

input[type="datetime-local"] {
  width: 12.5em;
  background-color: rgb(232, 240, 254);
  text-align: right;
  padding-left: 3px;
  padding-bottom: 1px;
  border: 1px solid black;
}

input[type="number"] {
  width: 7em;
  background-color: rgb(232, 240, 254);
  text-align: right;
  padding-left: 0px;
  padding-right: 0;
  padding-bottom: 1px;
  border: 1px solid black;
}

#dollarDiv {
  border-bottom: none;
  margin: 0;
  padding: 0;
}

.dollarSpan {
  position: absolute;
  margin-left: 3px;
  margin-top: 1px;
}

.currency {
  padding-left: 8px;
}

.newEventSubmitButtom {
  background-color: rgb(0, 82, 94);
  color: white;
  padding: 5px 8px 5px 8px;
  font-size: 1rem;
  font-weight: 500;
  margin: 0.5rem 0 1rem 0;
}

.newEventSubmitButtom:hover {
  opacity: 0.85;
}
