.slick-list {
  /* margin: 5px; */
  width: 100%;
  height: fit-content;
  /* outline: green thin dotted; */
}
.slick-slide > div {
  padding: 0px 0;
  border-radius: 8px;
  background-color: transparent;
  margin: 0 10px 0px 10px;
  /* outline: red thin dotted; */
}

.slick-track {
  margin: 0px 0px;
  padding: 1rem 0px;
}

ul.slick-dots {
  bottom: -50px;
}

.upcomingEventsMainDiv {
  margin: 50px auto;
  width: 95%;
  padding: 0rem 0rem;
  max-width: 1400px;
  /* outline: purple thin dotted; */
}

.upcomingEventsMainDiv h2 {
  padding-left: 0.5rem;
  font-weight: 300;
  font-size: 1.8rem;
  padding-bottom: 0;
  margin-bottom: 1rem;
}

.upcomingEventContainer {
  cursor: pointer;
  border-radius: 8px;
  /* outline: blue thin dotted; */
  height: 100%;
  padding: 0;
}

.upcomingEventDetailsContainer {
  padding: 0;
  color: #fff;
  height: 100%;
  transition: box-shadow 0.3s ease-in-out;
  /* box-shadow: 0 3px 2px rgba(0, 0, 0, 0.3); */
  box-shadow: rgba(0, 0, 0, 0.16) 0px 1px 4px;
  border-radius: 8px;
  /* outline: yellow thin dotted; */
  display: flex;
  flex-direction: column;
}

.upcomingEventDetailsContainer img {
  width: 100%;
  height: auto;
  aspect-ratio: 16/9;
  border-top-left-radius: 8px;
  border-top-right-radius: 8px;
  object-fit: cover;
}

.upcomingEventContainerMiddle {
  flex: 1;
  flex-grow: 1;
  padding: 0 10px 0 10px;
  /* outline: green thin dotted; */
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
  text-align: center;
  background-color: white;
}

.upcomingEventContainerMiddle h3 {
  color: #333;
  font-size: 1.2rem;
  font-weight: 900;
  overflow: hidden;
  display: -webkit-box;
  -webkit-line-clamp: 1;
  line-clamp: 1;
  -webkit-box-orient: vertical;
}

.upcomingEventContainerMiddle p {
  color: #333;
  overflow: hidden;
  font-size: 0.9rem;
  display: -webkit-box;
  -webkit-line-clamp: 1;
  line-clamp: 1;
  -webkit-box-orient: vertical;
  margin-top: 0;
  margin-bottom: 1rem;
}

.upcomingEventContainerMiddle h3:last-child {
  color: rgb(0, 82, 94);
  font-weight: 100;
  font-size: 1rem;
  margin: 0 0 1rem 0;
}

.upcomingEventContainerMiddle p {
}
.upcomingEventContainerFooter {
  display: flex;
  flex-direction: row;
  padding: 0px 0 0 0;
  border-bottom-left-radius: 8px;
  border-bottom-right-radius: 8px;
  background-color: #ed5628;
}

.upcomingEventContainerFooter div {
  display: flex;
  width: 50%;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.upcomingEventContainerFooter div:first-child {
  padding-left: 10px;
  border-bottom-left-radius: 8px;
}

.upcomingEventContainerFooter div:last-child {
  padding-right: 10px;
  border-bottom-right-radius: 8px;
}

.upcomingEventContainerFooter h4 {
  font-size: 0.9rem;
  font-weight: 300;
  line-height: 1rem;
}

.eventDate,
.eventHour {
  margin: 0;
}

.eventDate {
  white-space: nowrap;
}

.eventHour {
  white-space: nowrap;
}
