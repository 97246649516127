div.eventGallery {
  /* min-height: 200px; */
  /* outline: purple thin dotted; */
  width: 100%;
}

h1.eventGallery {
  font-size: 1.5rem;
  font-weight: 500;
  color: #333;
  margin-top: 2rem;
  margin-bottom: 1.5rem;
  padding: 0;
  width: 100%;
  border-top: #333 1px solid;
  padding-top: 1.5rem;
}

div.noImages {
  height: 100px;
  border: #333 1px solid;
  border-radius: 8px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}
div.noImages h1.noImages {
  font-size: 1.3rem;
  font-weight: 500;
  color: #333;
}

div.imagesCollection {
  /* width: 96%; */
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  grid-gap: 1rem;
  background-color: #f6f7f8;
  border-radius: 8px;
  padding: 0 0;
  margin: auto;
  /* outline: red thin dotted; */
}

div.imageContainer {
  /* outline: green thin dotted; */
  position: relative;
  overflow: hidden;
  aspect-ratio: 1; /* 1:1 aspect ratio to maintain a square */
  border-radius: 5px;
  cursor: pointer;
  background-color: transparent;
  transition: all 0.2s ease-in-out;
  background-color: none;
  box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.1);
}

img.eventThumbnail {
  /* outline: blue thin dotted; */
  /* height: 100%; */
  width: 100%;
  height: 100%;
  object-fit: cover;
  border-radius: 5px;
  cursor: pointer;
  background-color: transparent;
  transition: all 0.2s ease-in-out;
}

#popup {
  margin: 0;
  width: 100vw;
  /* height: 100vh; */

  padding: 0px;
}

div.modal {
  position: relative;
  background-color: transparent;
  margin: 0;
  padding: 0;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  /* outline: blue 2px dotted; */
  width: 100%;
  height: 100%;
}

button.close {
  position: absolute;
  margin: 0;
  top: 15px;
  right: 15px;
  padding: 0rem;
  cursor: pointer;
  color: white;
  font-size: 2rem;
  background-color: #359494;
  border-color: none;
  border-radius: 50%;
  width: 3rem;
  height: 3rem;
  border: none;
  /* z-index: 30; */
  /* background-color: red; */
  transition: all 0.2s ease-in-out;
}

button.trash {
  position: absolute;
  margin: 0;
  top: 80px;
  right: 15px;
  padding: 0.5rem;
  cursor: pointer;
  color: white;
  font-size: 1.3rem;
  background-color: #b51616;
  border-color: none;
  border-radius: 50%;
  width: 3rem;
  height: 3rem;
  border: none;
  transition: all 0.2s ease-in-out;
}

div.modal button:hover {
  scale: 0.9;
}

div.modal button:hover {
  scale: 0.9;
}

button.trash i {
  padding: 0;
  margin: auto;
}

img.modalImage {
  /* height: 50%; */
  /* position: relative; */
  max-height: 100vh;
  width: 100%;
  object-fit: contain;
  margin: 0;
  padding: 0;
}

@media only screen and (max-width: 580px) {
  div.imagesCollection {
    grid-template-columns: repeat(2, 1fr);
    grid-gap: 1rem;
  }
}
